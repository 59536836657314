import { lazy } from 'react';
import links, { transformProperties } from './links';

const Login = lazy(() => import('../pages/Login'));
const LoginAdmin = lazy(() => import('../pages/LoginAdmin'));
const Administrator = lazy(() => import('../pages/Administrator'));
const SubscribeTrial = lazy(() => import('../pages/SubscribeTrial'));
const SendRDStationForm = lazy(() => import('../pages/SendRDStationForm'));
const UsersAdd = lazy(() => import('../pages/Administrator/Users/Add'));
const KnowHostsAdd = lazy(() => import('../pages/Administrator/KnowHosts/Add'));
const KnowHostsPage = lazy(() => import('../pages/Administrator/KnowHosts'));
const UsersPage = lazy(() => import('../pages/Administrator/Users'));
const KnowHostsEdit = lazy(() =>
  import('../pages/Administrator/KnowHosts/Edit')
);
const Error404 = lazy(() => import('../pages/Errors/404'));

const routes = [
  {
    path: links.login.root,
    Component: Login,
    ...transformProperties(links.login),
  },
  {
    path: links.loginAdmin.root,
    Component: LoginAdmin,
    ...transformProperties(links.loginAdmin),
  },
  {
    path: links.subscribeTrial.root,
    Component: SubscribeTrial,
    ...transformProperties(links.subscribeTrial),
  },
  {
    path: links.sendRDStationForm.root,
    Component: SendRDStationForm,
    ...transformProperties(links.sendRDStationForm),
  },
  {
    path: links.administrator.root,
    Component: Administrator,
    ...transformProperties(links.administrator),
  },
  {
    path: links.administrator.users.add.root,
    Component: UsersAdd,
    ...transformProperties(links.administrator.users.add),
  },
  {
    path: links.administrator.users.edit.root,
    Component: UsersAdd,
    ...transformProperties(links.administrator.users.edit),
  },
  {
    path: links.administrator.knowHosts.add.root,
    Component: KnowHostsAdd,
    ...transformProperties(links.administrator.knowHosts.add),
  },
  {
    path: links.administrator.knowHosts.root,
    Component: KnowHostsPage,
    ...transformProperties(links.administrator.knowHosts),
  },
  {
    path: links.administrator.users.root,
    Component: UsersPage,
    ...transformProperties(links.administrator.users),
  },
  {
    path: links.administrator.knowHosts.edit.root,
    Component: KnowHostsEdit,
    ...transformProperties(links.administrator.knowHosts.edit),
  },
  {
    path: links.errors[404].root,
    Component: Error404,
    ...transformProperties(links.errors[404]),
  },
];

const config = {
  routes,
  route404: routes.filter((route) => route.path === links.errors[404].root)[0],
  defaultRouteTitle: links.defaultTitle,
  route401Admin: routes[1],
};

export default config;
