const links = {
  defaultTitle:
    'New Way - A transformação do relacionamento digital a seu alcance',
  login: {
    root: '/',
    title: 'New Way - A transformação do relacionamento digital a seu alcance',
  },
  loginAdmin: {
    root: '/login-admin',
    title: 'Login - SSO New Way',
  },
  subscribeTrial: {
    root: '/cadastro',
    title: 'Cadastro Trial - SSO New Way',
  },
  sendRDStationForm: {
    root: '/redirect-form',
    title: '',
  },
  administrator: {
    root: '/admin',
    title: 'Admin - SSO New Way',
    isAdmin: true,
    users: {
      root: '/admin/usuarios',
      title: 'Admin / Usuários - SSO New Way',
      isAdmin: true,
      add: {
        root: '/admin/usuarios/add',
        title: 'Admin / Usuários / Adicionar Novo - SSO New Way',
        isAdmin: true,
      },
      edit: {
        root: '/admin/usuarios/:id',
        title: 'Admin / Usuários / Alterar usuário - SSO New Way',
        isAdmin: true,
      },
    },
    knowHosts: {
      root: '/admin/sistemas',
      title: 'Admin / Sistemas - SSO New Way',
      isAdmin: true,
      add: {
        root: '/admin/sistemas/add',
        title: 'Admin / Sistemas / Adicionar Novo - SSO New Way',
        isAdmin: true,
      },
      edit: {
        root: '/admin/sistemas/edit/:id',
        title: 'Admin / Sistemas / Editar - SSO New Way',
        isAdmin: true,
      },
    },
  },
  errors: {
    404: {
      root: '/not-found',
      title: 'Não encontrado - SSO New Way',
    },
  },
};

function transformProperties(obj) {
  return {
    title: obj.title,
    isAdmin: obj.isAdmin,
  };
}

export { transformProperties };

export default links;
