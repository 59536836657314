import React from 'react';
import Footer from '@gruponewway/aquisicao-base-footer';
import '@gruponewway/aquisicao-base-footer/dist/index.css';
import { useTranslation } from 'react-i18next';
import links from '../../../routes/links';
import nwLogo from '../../../assets/images/commons/nw-logo.png';

import './styles.css';

function FooterAdmin() {
  const { t } = useTranslation();

  return (
    <Footer
      logoNavigationRoute={links.administrator.root}
      nwLogo={nwLogo}
      menuHome={t('common:menuHome')}
      commonTerms={t('common:terms')}
      commonNewWay={t('common:newWay')}
    />
  );
}

export default FooterAdmin;
