/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';

import ChangePageTitle from '../components/ChangePageTitle';
import PageWrapper from '../components/PageWrapper';
import AdminAuth from '../components/AdminAuth';

function Routes({ routes, pageProps }) {
  const location = useLocation();

  const route404 = routes && routes.route404;
  const route401Admin = routes && routes.route401Admin;

  const doSwitch = () => (
    <Switch location={location} key={location.pathname}>
      {routes.routes.map((route) => (
        <Route exact={route.exact ?? true} key={route.path} path={route.path}>
          <PageWrapper route={route}>
            <AdminAuth route={route} route401Admin={route401Admin}>
              <ChangePageTitle
                title={route.title}
                defaultTitle={routes.defaultRouteTitle}
                queryEl="h1"
                queryFallback="[tabindex]:not(h1)"
              />
              <route.Component
                {...(pageProps &&
                pageProps.filter((pageProp) => pageProp.path === route.path)
                  ?.length
                  ? pageProps
                      .filter((pageProp) => pageProp.path === route.path)
                      .map((pageProp) => pageProp.props)
                      .reduce((obj, menuProps) => ({
                        ...obj,
                        ...menuProps,
                      }))
                  : {})}
              />
            </AdminAuth>
          </PageWrapper>
        </Route>
      ))}
      {route404 && <Redirect to={route404.path} />}
    </Switch>
  );

  return (
    <main id="main-section">
      {global.__SERVER__ ? (
        doSwitch()
      ) : (
        <React.Suspense fallback={<div className="suspense" />}>
          {doSwitch()}
        </React.Suspense>
      )}
    </main>
  );
}

export default Routes;
