import React, { useState, useEffect, useCallback } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import useAdminToken from '../../hooks/UseAdminToken';
import { CheckAuthentication, GetAdminToken } from '../../utils/adminToken';

function AdminAuth({ route, route401Admin, children }) {
  const token = useAdminToken();
  const isAdmin = route && route.isAdmin;
  const [authenticated, setAuthenticated] = useState(token);
  const location = useLocation();

  const CheckAuthenticationCallback = useCallback(async () => {
    await CheckAuthentication(token).then(() =>
      setAuthenticated(GetAdminToken())
    );
  }, [setAuthenticated, token]);

  useEffect(() => {
    if (isAdmin) CheckAuthenticationCallback();
  }, [CheckAuthenticationCallback]);

  if (isAdmin && !authenticated)
    return (
      <Redirect
        to={`${route401Admin.path}?redirect=${location.pathname}${
          location.search !== '?' ? `${location.search}` : ''
        }`}
      />
    );

  return children;
}

export default AdminAuth;
