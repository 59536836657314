import React from 'react';
import { I18nextProvider } from 'react-i18next';
import Helmet from 'react-helmet';
import i18n, { getLanguage } from './i18n';
import GoogleLoginConsumer from './contexts/GoogleLogin';

import App from './App';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function Body({ routes, pageProps }) {
  return (
    <I18nextProvider i18n={i18n}>
      <GoogleLoginConsumer
        clientId={clientId}
        isSignedIn={false}
        accessType="offline"
        uxMode="redirect"
        scopes={['openid', 'profile', 'email']}
      >
        <Helmet htmlAttributes={{ lang: getLanguage() }} />
        <App routes={routes} pageProps={pageProps} />
      </GoogleLoginConsumer>
    </I18nextProvider>
  );
}

export default Body;
