import { useState, useCallback, useEffect } from 'react';
import { GetAdminToken } from '../../utils/adminToken';

function useAdminToken() {
  const [token, setToken] = useState(GetAdminToken());

  const setTokenCallback = useCallback(
    (event) => {
      setToken(event.detail.token);
    },
    [setToken]
  );

  useEffect(() => {
    window.addEventListener('admin-token-changed', setTokenCallback);
    return () => {
      window.removeEventListener('admin-token-changed', setTokenCallback);
    };
  }, [setTokenCallback]);

  return token;
}

export default useAdminToken;
