/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Footer from '../Base/Footer';
import FooterAdmin from '../Base/FooterAdmin';

function FooterApp() {
  const history = useHistory();

  const footers = { default: <Footer />, admin: <FooterAdmin /> };

  const identifyFooter = (location) => {
    const paths = location.pathname.split('/').filter((item) => item !== '');
    return paths.length && footers[paths[0]] !== undefined
      ? paths[0]
      : 'default';
  };

  const [footerType, setFooterType] = useState(
    identifyFooter(history.location)
  );

  history.listen((location) => {
    setFooterType(identifyFooter(location));
  });

  return footers[footerType];
}

export default FooterApp;
