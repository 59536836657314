import login from './login.json';
import common from './common.json';
import users from './users.json';
import knowHost from './knowHost.json';
import subscribeTrial from './subscribeTrial.json';
import errors from './errors.json';

const lng = {
  en: {
    login,
    common,
    users,
    knowHost,
    subscribeTrial,
    errors,
  },
};

export default lng;
