/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Menu from '../Base/Menu';
import MenuAdmin from '../Base/MenuAdmin';

import './styles.css';

function MenuApp() {
  const history = useHistory();

  const menus = { default: <Menu />, admin: <MenuAdmin /> };

  const identifyMenu = (location) => {
    const paths = location.pathname.split('/').filter((item) => item !== '');
    return paths.length && menus[paths[0]] !== undefined ? paths[0] : 'default';
  };

  const [menuType, setMenuType] = useState(identifyMenu(history.location));

  history.listen((location) => {
    setMenuType(identifyMenu(location));
  });

  return menus[menuType];
}

export default MenuApp;
