import React, { createContext } from 'react';
import { useGoogleLogin } from 'react-google-login';

const GoogleLoginContext = createContext();

const GoogleLoginConsumer = ({ children, ...input }) => {
  const loginResponse = useGoogleLogin(input);

  return (
    <GoogleLoginContext.Provider value={loginResponse}>
      {children}
    </GoogleLoginContext.Provider>
  );
};

export { GoogleLoginContext };

export default GoogleLoginConsumer;
