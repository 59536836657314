import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import nwLogo from '../../../assets/images/commons/nw-logo-mini.png';
import setAcessibilityFocus from '../../../utils/acessibilityFocusMainContent';
import links from '../../../routes/links';

import './styles.css';

function Menu() {
  const { t } = useTranslation();

  return (
    <header id="header-section">
      <Container fluid id="navbar-container">
        <Container fluid id="navbar-content">
          <div className="navbar-wrapper animate__animated animate__fadeInDown animate__faster">
            <Navbar
              id="navbar"
              className="navbar-blog"
              collapseOnSelect
              expand="lg"
            >
              <h2 className="hidden">{t('common:siteNavigation')}</h2>
              <Nav.Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setAcessibilityFocus();
                }}
                className="hide-visibility"
                id="go-to-main-content"
              >
                {t('common:menuGoToMainContent')}
              </Nav.Link>
              <Navbar.Brand className="">
                <Link to={links.login.root}>
                  <div className="blog-brand-container">
                    <span className="hide-visibility" id="logo-link">
                      {t('common:menuHome')}
                    </span>
                    <figure>
                      <img src={nwLogo} alt="" />
                    </figure>
                  </div>
                </Link>
              </Navbar.Brand>
            </Navbar>
          </div>
        </Container>
      </Container>
    </header>
  );
}

export default Menu;
