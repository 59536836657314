import React from 'react';
import { useTranslation } from 'react-i18next';
import Accessibilities from '@gruponewway/aquisicao-accessibilities';
import '@gruponewway/aquisicao-accessibilities/dist/index.css';
import interopDefault from '../../utils/interopDefault';

const InteropAccessibilities = interopDefault(Accessibilities);

const headerSelector = 'header#header-section';
const mainSelector = 'main#main-section';
const footerSelector = 'footer#footer-section';

const headerTargets = [
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'h7',
  'p',
  'span:not(.navbar-toggler-icon)',
  'li',
  'a',
  'label',
  'input',
];

const mainTargets = [
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'select',
  'option',
  'p.h1',
  'p.description',
  'span',
  'label',
  'input',
];

const footerTargets = [
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'h7',
  'p',
  'span',
  'li',
  'a',
];

const targets = [
  ...headerTargets.map((val) => `${headerSelector} ${val}`),
  ...mainTargets.map((val) => `${mainSelector} ${val}`),
  ...footerTargets.map((val) => `${footerSelector} ${val}`),
];

function MyAccessibilities() {
  const { t } = useTranslation();
  return (
    <InteropAccessibilities
      targets={targets}
      showAccessibilityOptions={t(
        'common:accessibility.showAccessibilityOptions'
      )}
      hideAccessibilityOptions={t(
        'common:accessibility.hideAccessibilityOptions'
      )}
      changeFontUp={t('common:accessibility.changeFontUp')}
      changeFontDown={t('common:accessibility.changeFontDown')}
      changeContrast={t('common:accessibility.changeContrast')}
    />
  );
}

export default MyAccessibilities;
