import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderAdministrator from '../HeaderAdministrator';
import nwLogo from '../../../assets/images/commons/nw-logo-mini.png';
import { RemoveAdminToken } from '../../../utils/adminToken';

function MenuAdmin() {
  const { t } = useTranslation();

  return (
    <HeaderAdministrator
      nwLogo={nwLogo}
      handleLogOut={RemoveAdminToken}
      logoutText={t('common:btnLogout')}
    />
  );
}

export default MenuAdmin;
