import React from 'react';
import ReactDOM from 'react-dom';
import AOS from 'aos';
import { BrowserRouter } from 'react-router-dom';
import routes from './routes/routes_lazy';

global.__CLIENT__ = true;
global.__SERVER__ = false;
global.__DEVELOPMENT__ = process.env.NODE_ENV !== 'production';
global.__TEST__ = process.env.NODE_ENV === 'test';

const Body = require('./body').default;

AOS.init({
  once: true,
});

window.onbeforeunload = () => {
  window.scrollTo(0, 0);
};

function RenderToDOM() {
  if (document.getElementById('root'))
    ReactDOM.render(
      <BrowserRouter>
        <Body routes={routes} />
      </BrowserRouter>,
      document.getElementById('root')
    );
}

RenderToDOM();

export { RenderToDOM };
export default Body;
