/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import MenuApp from './components/MenuApp';
import Routes from './routes';
import FooterApp from './components/FooterApp';

import Accessibilities from './components/Accessibilities';
import { useTheme } from './hooks/UseTheme';
import links from './routes/links';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/theme.scss';
import './assets/styles/global.css';
import 'aos/dist/aos.css';

const pageBlank = [];

const pageBgLayoutNotDefault = [links.subscribeTrial.root];

function App({ routes, pageProps }) {
  const history = useHistory();
  const [isPageBlank, setIsPageBlank] = useState(
    pageBlank.includes(history?.location?.pathname)
  );
  const theme = useTheme();

  const grayBackground =
    history?.location?.pathname === pageBgLayoutNotDefault[0];
  const backgroundStyle = grayBackground ? 'gray-bg' : 'default-bg';

  history &&
    history.listen((location) => {
      setIsPageBlank(pageBlank.includes(location.pathname));
    });

  return (
    <div className={`App ${theme} ${backgroundStyle}`}>
      <div id="app-wrapper">
        {!isPageBlank && <MenuApp />}

        <Routes routes={routes} pageProps={pageProps} />

        {!isPageBlank && <Accessibilities />}
        {!isPageBlank && <FooterApp />}
      </div>
    </div>
  );
}

export default App;
