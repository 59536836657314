/* eslint-disable no-unused-expressions */
import fetchWithoutToken from './fetchWithoutToken';

function GetAdminToken() {
  if (undefined !== window && undefined !== window.sessionStorage)
    return window.sessionStorage.getItem('admin-token');
}

function DispatchTokenChanged() {
  if (undefined !== window && window.dispatchEvent) {
    window.dispatchEvent(
      new CustomEvent('admin-token-changed', {
        detail: { token: GetAdminToken() },
      })
    );
  }
}

function SetAdminToken(token, dispatch = true) {
  if (undefined !== window && undefined !== window.sessionStorage) {
    window.sessionStorage.setItem('admin-token', token);
    dispatch && DispatchTokenChanged();
  }
}

function RemoveAdminToken(dispatch = true) {
  if (undefined !== window && undefined !== window.sessionStorage) {
    window.sessionStorage.removeItem('admin-token');
    dispatch && DispatchTokenChanged();
  }
}

function CheckAuthentication(token) {
  return new Promise((resolve) => {
    if (undefined !== token)
      fetchWithoutToken(
        `${process.env.REACT_APP_SERVICE_API}api/v1/admin/authenticate/${token}`
      )
        .then(() => resolve(true))
        .catch(() => {
          RemoveAdminToken();
          resolve(false);
        });
    resolve(false);
  });
}

export { GetAdminToken, SetAdminToken, CheckAuthentication, RemoveAdminToken };
